<template>
    <div class="checkbox" :class="computedClasses">
        <div class="checkbox__box">
            <input
                class="checkbox__input"
                type="checkbox"
                :checked="isChecked"
                :id="id"
                :name="name"
                :value="value"
                v-model="isChecked"
                @change="handleChange"
            />
            <CheckboxSvg />
        </div>
        <label class="checkbox__label" :for="id"><slot /></label>
    </div>
</template>

<script>
import CheckboxSvg from '@module/CheckboxSvg.vue';

export default {
    props: {
        name: {
            type: String,
        },
        checked: {
            type: Boolean,
            default: true,
        },
        value: {
            type: [String, Number],
        },
    },

    data() {
        return {
            id: null,
            isChecked: false,
            internalValue: null,
        };
    },

    computed: {
        computedClasses() {
            return {
                '--checked': this.isChecked,
            };
        },
    },
    methods: {
        handleChange() {
            this.$emit('input', this.isChecked);
        },
    },
    mounted() {
        this.isChecked = this.checked;
        this.id = this._uid;
    },
    components: {
        CheckboxSvg,
    },
};
</script>
