<template>
    <div class="dropdown-link" :class="modifierArray">
        <Link v-if="link" :destination="link">
            <div class="dropdown-link__inner">
                <Icon v-if="computedIcon" :icon="computedIcon" class="dropdown-link__icon" />

                <span class="dropdown-link__content">
                    <slot />
                </span>
            </div>
        </Link>

        <template v-else>
            <button v-on="$listeners" class="dropdown-link__inner dropdown-link__button">
                <Icon v-if="computedIcon" :icon="computedIcon" class="dropdown-link__icon" />

                <span class="dropdown-link__content">
                    <slot />
                </span>
            </button>
        </template>
    </div>
</template>

<script>
import Link from '@module/Link';
import Icon from '@module/Icon';
import links from '@mixin/links';
import modifier from '@mixin/modifier';

export default {
    name: 'DropdownItem',
    computed: {
        computedIcon() {
            if (this.icon === false) return false;
            if (this.icon) {
                return this.icon;
            } else if (!this.isInternalUrl(this.link)) {
                return 'external';
            } else {
                return '';
            }
        },
    },
    props: {
        link: {
            type: String,
        },
        icon: {
            type: [String, Boolean],
        },
    },
    components: {
        Icon,
        Link,
    },
    mixins: [modifier, links],
};
</script>
