import CompleteLinkBlock from '@fragment/shared/CompleteLinkBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteNavigationItemBlock on NavigationItemBlock {
        link {
            ...CompleteLinkBlock
        }
        linkType
    }
    ${CompleteLinkBlock}
`;
