export default {
    methods: {
        isInternalUrl(url) {
            if (url) {
                return url[0] === '/';
            }
        },
        isAnchorLink(url) {
            if (url) {
                return url[0] === '#';
            }
        },
        getUrlFromLinkObj(linkObj) {
            if (typeof linkObj === 'string') return linkObj;
            switch (linkObj.linkTo) {
                case 'page':
                    return linkObj.page.url;
                case 'file':
                    return linkObj.file.url;
                case 'custom_url':
                    return linkObj.customUrl;
                case 'anchor':
                    return linkObj.anchor.charAt(0) === '#' ? linkObj.anchor : `#${linkObj.anchor}`;
                default:
                    return '';
            }
        },
    },
};
