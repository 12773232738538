import PagePreview from '@fragment/previews/PagePreview';
import CompletePLEvent from '@fragment/shared/CompletePLEvent';
import UserFolderPreview from '@fragment/previews/UserFolderPreview';
import CompleteUserFolderConfig from '@fragment/shared/CompleteUserFolderConfig';
import CompleteLocation from '@fragment/shared/CompleteLocation';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteUser on CustomUserNode {
        id
        email
        firstName
        lastName
        access
        organization
        hasOrganization
        confirmedOrganization
        acceptedLatestTou
        schoolOrOrganization
        schoolOrOrganizationId
        subscribeLink
        customerPortalLink
        subscriptionPeriodEnd
        subscriptionCancelAt
        access
        roles {
            id
            name
        }
        marketingEvents {
            ...PagePreview
        }
        professionalLearningEvents {
            ...CompletePLEvent
        }
        curriculum {
            ...PagePreview
        }
        folders {
            ...UserFolderPreview
        }
        recentlyViewedResources {
            ...PagePreview
        }
        recentlySavedResources {
            ...PagePreview
        }
        folderConfigs {
            ...CompleteUserFolderConfig
        }
        pageUrlPaths {
            key
            path
        }
        location {
            ...CompleteLocation
        }
    }
    ${PagePreview}
    ${CompletePLEvent}
    ${UserFolderPreview}
    ${CompleteUserFolderConfig}
    ${CompleteLocation}
`;
