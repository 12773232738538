<template>
    <div class="site-modals" v-show="hasModals" @keyup.esc="removeAllModals">
        <div class="site-modals__curtain" :class="curtainStyle" @click="removeAllModals" />
        <SiteModal :class="{ '--notice': modal.notice }" v-for="modal in modals" :key="modal.uuid">
            <component
                :is="modalComponent(modal)"
                v-bind="modalProps(modal)"
                @close-modal="removeModal(modal)"
                @close-notice="removeModal(modal)"
            />
        </SiteModal>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import SiteModal from '@global/SiteModal';

export default {
    watch: {
        $route() {
            this.removeAllModals();
        },
    },
    computed: {
        ...mapGetters('siteModals', ['modals', 'hasModals']),
        curtainStyle() {
            const solid = this.modals.find((modal) => modal.solidCurtain) !== undefined;
            return solid ? '--solid' : '--translucent';
        },
    },
    methods: {
        ...mapActions('siteModals', ['addModal', 'removeModal', 'removeAllModals']),
        modalProps(modal) {
            return {
                ...modal.props,
            };
        },
        modalComponent(modal) {
            return {
                ...modal.component,
            };
        },
    },
    components: {
        SiteModal,
    },
};
</script>
