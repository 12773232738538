import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteResourceSearchResultItem on Resource {
        __typename
        id
        title
        url
        description
        thumbnailImage {
            ...CompleteCustomImage
        }
        resourceTypes {
            id
            name
        }
        moeTypes {
            id
            name
        }
    }
    ${CompleteCustomImage}
`;
