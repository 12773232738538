import gql from 'graphql-tag';

export default gql`
    fragment CompleteUserFolderConfig on UserFolderConfig {
        name
        indexPageTitle
        singleFolder
        defaultFolderName
        allowedTypes {
            type
            typeName
        }
    }
`;
