import CompleteRegistrationLinkBlock from '@fragment/shared/CompleteRegistrationLinkBlock';
import CompleteMarketingPhysicalLocationBlock from '@fragment/shared/CompleteMarketingPhysicalLocationBlock';
import CompleteMarketingEventVirtualLocationBlock from '@fragment/shared/CompleteMarketingEventVirtualLocationBlock';
import CompletePLDate from '@fragment/shared/CompletePLDate';
import gql from 'graphql-tag';

export default gql`
    fragment CompletePLEvent on PLEvent {
        id
        title
        plCatalogEntry {
            title
            type
        }
        description
        topics {
            name
        }
        format {
            name
        }
        eligibility {
            id
            name
        }
        memberPrice
        nonmemberPrice
        registrationLink {
            ...CompleteRegistrationLinkBlock
        }
        eventLocation {
            ...CompleteMarketingPhysicalLocationBlock
            ...CompleteMarketingEventVirtualLocationBlock
        }
        timezone
        startDate
        endDate
        plEventDates {
            ...CompletePLDate
        }
    }
    ${CompleteRegistrationLinkBlock}
    ${CompleteMarketingPhysicalLocationBlock}
    ${CompleteMarketingEventVirtualLocationBlock}
    ${CompletePLDate}
`;
