<template>
    <div class="notice-layout">
        <Nuxt></Nuxt>
        <SiteModals />
    </div>
</template>

<script>
import SiteModals from '@global/SiteModals';
export default {
    components: {
        SiteModals,
    },
};
</script>
