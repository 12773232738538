import CompleteDocumentChooserBlock from '@fragment/shared/CompleteDocumentChooserBlock';
import CompleteGoogleDriveBlock from '@fragment/shared/CompleteGoogleDriveBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteDocumentBlock on DocumentBlock {
        blockType
        id
        inlinePreview
        document {
            id
            title
            description
            documents {
                ...CompleteDocumentChooserBlock
                ...CompleteGoogleDriveBlock
            }
            requiresAuth
        }
    }
    ${CompleteDocumentChooserBlock}
    ${CompleteGoogleDriveBlock}
`;
