import gql from 'graphql-tag';

export default gql`
    fragment CompleteVideoEmbedBlock on VideoEmbedBlock {
        type
        url
        oEmbed {
            html
            width
            height
            thumbnailUrl
            authorName
            authorUrl
            providerName
            providerUrl
            type
            title
        }
    }
`;
