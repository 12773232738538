<template>
    <div
        @click="handleToggle"
        :class="({ '--open': sharedState.active }, modifierArray)"
        v-closable="{
            exclude: ['toggle-button', 'toggle-icon'],
            handler: 'handleClickAway',
        }"
        ref="dropdown"
        class="dropdown button"
    >
        <button ref="toggle-button" class="dropdown__toggle">
            <span class="dropdown__toggle__content">
                <label>
                    <slot name="toggler"> Toggle </slot>
                </label>
                <Icon v-if="icon" :icon="icon" class="toggle-icon" />
            </span>
            <Icon v-if="!icon" icon="dropdown" class="toggle-icon" />
        </button>
        <div class="dropdown__content" ref="dropdown-content">
            <slot />
        </div>
    </div>
</template>

<script>
import modifier from '@mixin/modifier';
import Icon from '@module/Icon';

export default {
    name: 'Dropdown',
    mixins: [modifier],
    provide() {
        return {
            sharedState: this.sharedState,
            dropdownWidth: this.sharedState.dropdownWidth,
        };
    },
    data() {
        return {
            sharedState: {
                active: false,
                dropdownWidth: 0,
            },
            dropdownFirstFocusItem: null,
            dropdownLastFocusItem: null,
        };
    },
    methods: {
        close() {
            this.sharedState.active = false;
        },
        handleToggle(e) {
            if (!this.$refs['dropdown-content'].contains(e.target)) {
                this.sharedState.active = !this.sharedState.active;
            }
            this.$emit('click');
        },
        handleClickAway() {
            this.sharedState.active = false;
        },
        getFocusItems() {
            this.$nextTick(() => {
                const dropdown = this.$refs['dropdown-content'];

                const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

                const dropdownFocusItems = dropdown?.querySelectorAll(focusableElements);
                this.dropdownFirstFocusItem = dropdownFocusItems?.[0];
                this.dropdownLastFocusItem = dropdownFocusItems?.[dropdownFocusItems.length - 1];
                document.addEventListener('keydown', this.tabHandler);
            });
        },
        tabHandler(e) {
            let isTabPressed = e?.key === 'Tab';

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) {
                if (document.activeElement === this.dropdownFirstFocusItem) {
                    this.sharedState.active = false;
                }
            } else {
                if (document.activeElement === this.dropdownLastFocusItem) {
                    this.sharedState.active = false;
                }
            }
        },
    },
    mounted() {
        this.sharedState.dropdownWidth = this.setMinWidth && this.$refs['dropdown'].offsetWidth;
        this.getFocusItems();
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.tabHandler);
    },
    props: {
        icon: {
            type: String,
        },
        setMinWidth: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        Icon,
    },
};
</script>
