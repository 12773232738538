import gql from 'graphql-tag';

export default gql`
    fragment CurriculumLessonPagePreview on CurriculumLessonPage {
        url
        urlPath
        title
        subtitle
        summary
        gradePage {
            id
            title
            url
        }
        componentType
        moduleNumber
        unitNumber
        lessonNumber
        assessments {
            assessment {
                id
                title
                introduction
            }
        }
    }
`;
