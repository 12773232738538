<template>
    <footer class="footer invert" aria-label="Footer" ref="siteFooter">
        <div class="wrapper">
            <nuxt-link to="/" class="footer__logo">
                <img src="~/assets/images/el-logo-white.svg" alt="EL Education" />
            </nuxt-link>

            <div class="footer__nav" role="region" aria-label="Footer Nav">
                <ul class="footer__nav__items" v-if="primaryMenu">
                    <li class="footer__nav__item" v-for="navItem in parseNavItems(primaryMenu)" :key="navItem.urlPath">
                        <ButtonText v-if="!isExternalLink(navItem)" :link="navItem.urlPath">{{
                            navItem.label
                        }}</ButtonText>
                        <a v-else :href="navItem.urlPath" target="_blank" class="button__button-text">{{
                            navItem.label
                        }}</a>
                    </li>
                </ul>
            </div>

            <div class="footer__social" role="region" aria-label="Social links">
                <ul class="footer__social__items" v-if="socialMenu">
                    <li
                        class="footer__social__item"
                        v-for="navItem in parseNavItems(socialMenu)"
                        :key="navItem.urlPath"
                    >
                        <a class="footer__social__link" target="_blank" :href="navItem.urlPath">
                            <img v-if="navItem.icon.facebook" src="~/assets/images/facebook-icon.svg" alt="Facebook" />
                            <img v-if="navItem.icon.linkedIn" src="~/assets/images/linkedin-icon.svg" alt="LinkedIn" />
                            <img
                                v-if="navItem.icon.instagram"
                                src="~/assets/images/instagram-icon.svg"
                                alt="Instagram"
                            />
                            <img v-if="navItem.icon.twitter" src="~/assets/images/twitter-icon.svg" alt="Twitter" />
                            <img v-if="navItem.icon.tiktok" src="~/assets/images/tiktok-icon.svg" alt="TikTok" />
                        </a>
                    </li>
                </ul>
            </div>

            <div class="footer__copyright" role="region" aria-label="Copyright info">
                <div class="footer__copyright__year">&copy; {{ currentYear }} EL Education</div>
            </div>

            <div class="footer__action" role="region" aria-label="Call to action">
                <ul class="footer__action__items" v-if="formsMenu">
                    <li class="footer__action__item" v-for="navItem in parseNavItems(formsMenu)" :key="navItem.urlPath">
                        <ButtonComponent :destination="navItem.urlPath" :modifier="navItem.modifier" dir=">">{{
                            navItem.label
                        }}</ButtonComponent>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
import ButtonComponent from '@module/ButtonComponent';
import ButtonText from '@module/ButtonText';
import menus from '@mixin/menus';

export default {
    name: 'SiteFooter',
    mixins: [menus],

    data: () => ({
        primaryMenu: {},
        socialMenu: {},
        formsMenu: {},
    }),

    methods: {
        async setupMenus() {
            const menus = await this.getMenus('footer');
            if (menus) {
                this.primaryMenu = menus.find((menu) => menu.navigationMenuKey === 'site-footer-primary');
                this.socialMenu = menus.find((menu) => menu.navigationMenuKey === 'site-footer-social');
                this.formsMenu = menus.find((menu) => menu.navigationMenuKey === 'site-footer-forms');
            }
        },

        parseNavItems(menu) {
            const navItems = menu.NavigationMenuBlock || [];
            const menuKey = menu.navigationMenuKey;

            return navItems.map((navItem) => {
                if (menuKey === 'site-footer-social') {
                    return this.parseSocialNavItem(navItem);
                } else if (navItem.link.linkTo === 'page') {
                    return this.parsePageNavItem(navItem);
                } else if (navItem.link.linkTo === 'custom_url') {
                    return this.parseCustomNavItem(navItem);
                }
            });
        },

        parsePageNavItem(navItem) {
            return {
                urlPath: navItem.link.page.url,
                label: navItem.link.text?.length ? navItem.link.text : navItem.link.page.title,
                modifier: navItem.linkType,
                linkTo: navItem.linkTo,
            };
        },

        parseCustomNavItem(navItem) {
            return {
                urlPath: navItem.link.customUrl,
                label: navItem.link.text,
                linkTo: navItem.link.linkTo,
            };
        },

        parseSocialNavItem(navItem) {
            const urlPath = navItem.link.customUrl;
            return {
                urlPath,
                icon: {
                    facebook: urlPath.toLowerCase().includes('facebook'),
                    linkedIn: urlPath.toLowerCase().includes('linkedin'),
                    instagram: urlPath.toLowerCase().includes('instagram'),
                    twitter: urlPath.toLowerCase().includes('twitter'),
                    tiktok: urlPath.toLowerCase().includes('tiktok'),
                },
            };
        },
        isExternalLink(item) {
            return item.linkTo === 'custom_url';
        },
    },
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
    },
    created() {
        this.setupMenus();
    },

    async mounted() {
        if (process.client) {
            this.footerObserver = new ResizeObserver(() => {
                document.body.style.setProperty('--footer-height', this.$refs.siteFooter.clientHeight);
            });
        }

        if (this.footerObserver) {
            this.footerObserver.observe(this.$refs.siteFooter);
        }
    },
    beforeDestroy() {
        if (this.footerObserver) {
            this.footerObserver.disconnect();
        }
    },

    components: {
        ButtonComponent,
        ButtonText,
    },
};
</script>
