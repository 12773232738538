import Vue from 'vue';
const setFocalPoint = (el, binding) => {
    const altText = (image) => image.altText || image.title;
    const containerStyles = (image, focalPoint) =>
        `background-image: url(${image.url}); background-position: ${focalPoint.xPosition}% ${
            focalPoint.yPosition
        }%; background-size: ${image.fit || 'cover'};`;

    const centerFocalPoint = (image) => {
        const { width, height } = image;
        const { width: containerWidth, height: containerHeight } = el.getBoundingClientRect();

        let { focalPointX, focalPointY } = image;

        // If no focal point is set, default to center
        focalPointX = isNaN(parseFloat(focalPointX)) ? width / 2 : focalPointX;
        focalPointY = isNaN(parseFloat(focalPointY)) ? height / 2 : focalPointY;

        const xPercent = ((focalPointX / width) * 100).toFixed(2);
        const yPercent = ((focalPointY / height) * 100).toFixed(2);
        const widthRatio = Math.max(width / containerWidth, 2);
        const heightRatio = Math.max(height / containerHeight, 2);

        const xPosition = (xPercent - 50) * (widthRatio / (widthRatio - 1)) + 50;
        const yPosition = (yPercent - 50) * (heightRatio / (heightRatio - 1)) + 50;

        return {
            xPosition,
            yPosition,
        };
    };

    el.setAttribute('role', 'img');
    el.setAttribute('aria-label', altText(binding.value));
    el.setAttribute('style', containerStyles(binding.value, centerFocalPoint(binding.value)));
};
Vue.directive('background-image', {
    bind(el, binding) {
        if (!binding.value) {
            return;
        }

        el.resizeObserver = new ResizeObserver(() => {
            setFocalPoint(el, binding);
        });

        el.resizeObserver.observe(el);
    },

    unbind(el) {
        if (el.resizeObserver) {
            el.resizeObserver.unobserve(el);
            el.resizeObserver.disconnect();
            el.resizeObserver = null;
        }
    },
});
