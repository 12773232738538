<template>
    <BaseForm class="custom-form" :formUrl="this.formUrl" />
</template>

<script>
import BaseForm from '@forms/BaseForm';

export default {
    props: {
        formUrl: {
            type: String,
            default: '',
        },
    },

    components: {
        BaseForm,
    },
};
</script>
