<template>
    <component v-if="icon" :is="iconComponent" :class="modifierArray" :color="color" class="icon" />
    <span v-else></span>
</template>

<script>
import modifier from '@mixin/modifier';

export default {
    mixins: [modifier],
    computed: {
        iconComponent() {
            // This line is needed because the class is not picked up on unless you remind vue it is there.
            // This is a weird compile time bug where vue is being too efficient.
            // eslint-disable-next-line no-unused-vars
            const classCheck = (this.$attrs.class ?? '') || (this.$vnode.data?.staticClass ?? '');
            // We can now get the component we want.
            const icon = this.icon;
            return () => import(`./icons-action/${icon}.svg.vue`);
        },
    },
    props: {
        icon: {
            type: String,
        },
        color: {
            type: String,
        },
    },
};
</script>
