export const state = () => ({
    showNavigation: true,
});

export const getters = {
    isNavigationVisible: (state) => state.showNavigation,
};

export const mutations = {
    setNavigationVisibility(state, visibility) {
        state.showNavigation = visibility;
    },
};

export const actions = {
    setNavigationVisibility({ commit }, visibility) {
        commit('setNavigationVisibility', visibility);
    },
};
