<template>
    <div class="feedback__tab">
        <button class="button-text" @click="launchFeedbackModal()">Tech Support</button>
    </div>
</template>

<script>
import FeedbackForm from '@forms/FeedbackForm';

export default {
    methods: {
        launchFeedbackModal() {
            this.$modals.launch({
                component: FeedbackForm,
            });
        },
    },
};
</script>
