<template>
    <div class="notice">
        <slot name="message">
            <h5 class="big-body --bold">{{ title }}</h5>
            <p>{{ message }}</p>
        </slot>

        <div class="notice__actions" v-if="actions || $slots.actions">
            <slot name="actions">
                <button
                    class="button button-text"
                    :class="`--${action.style}`"
                    v-for="action in actions"
                    :key="action.eventName"
                    @click="handleAction(action)"
                >
                    {{ action.label }}
                </button>
            </slot>
        </div>

        <div class="notice__footer" v-if="footer || $slots.footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: 'Notice',
        },
        message: {
            type: String,
            default: '',
        },
        actions: {
            type: [Array, Boolean],
            default: () => [
                {
                    eventName: 'close-notice',
                    label: 'Close',
                },
            ],
        },
        footer: {
            type: Function,
        },
    },

    methods: {
        handleAction(action) {
            if (action.link) {
                this.$router.push({
                    path: action.link,
                });
            } else if (action.callback) {
                action.callback();
            }
            this.$emit('close-notice');
        },
    },
};
</script>
