import Vue from 'vue';
import { mapActions } from 'vuex';

export default (context, inject) => {
    function dispatchModal(config, type = 'modal') {
        let modalInstance = {
            close() {
                context.store.dispatch('siteModals/removeModal', this);
            },
        };
        const dispatchSignature = type === 'modal' ? 'siteModals/addModal' : 'siteModals/showNotice';
        context.store.dispatch(dispatchSignature, config).then((modal) => Object.assign(modalInstance, modal));
        return modalInstance;
    }
    
    // Apply mixin selectively or conditionally if needed
    if (!Vue.__modal_mixin__) {
        Vue.__modal_mixin__ = true
        Vue.mixin({
            methods: {
                ...mapActions('siteModals', {
                    launchModal: 'addModal',
                    showNotice: 'showNotice',
                }),
            },
        });
    }

    inject('modals', {
        launch: (config) => dispatchModal(config),
    });

    inject('notices', {
        show: (config) => dispatchModal(config, 'notice'),
        confirm: (config) => dispatchModal({ title: 'Please Confirm', ...config }, 'notice'),
    });
};
