<template>
    <SiteNotice>
        <template #message>
            <h5 class="big-body --bold">Please verify your email address</h5>
            <p>We have sent an email to {{ email }} in order to verify your email address</p>
        </template>

        <template #actions>
            <button class="button button-text --crimson" @click="closeNotice">Close</button>
        </template>

        <template #footer v-if="showFooter">
            <div v-if="resendingActivationEmail">Sent!</div>
            <a v-else @click="requestActivationEmail">Request to resend verification email.</a>
        </template>
    </SiteNotice>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SiteNotice from '@global/SiteNotice';

export default {
    props: {
        email: {
            type: String,
        },
    },

    computed: {
        ...mapState('user', ['resendingActivationEmail', 'changingEmail']),

        showFooter() {
            return !this.changingEmail;
        },
    },

    methods: {
        ...mapActions('user', ['resendActivationEmail']),

        requestActivationEmail() {
            this.resendActivationEmail(this.email);
        },

        closeNotice() {
            this.$emit('close-notice');
            this.$router.push({
                path: '/',
            });
        },
    },

    components: {
        SiteNotice,
    },
};
</script>
