<template>
    <BaseForm class="login-form" :fields="formFields" :errors="formErrors" :autoFocus="true" @submit="handleLogin">
        <template #title>
            <h5 class="big-body --bold">Log In</h5>
        </template>
        <template #actions>
            <input type="submit" value="Login" />
        </template>
        <template #subActions>
            <button class="button__button-link" @click="forgotPassword">Forgot Your Password?</button>
        </template>
        <template #footer>
            <div class="login-form__footer">
                <h5 class="big-body --bold">Don't have an account yet?</h5>
                <a class="button" href="/create-an-account">Create an account</a>
            </div>
        </template>
    </BaseForm>
</template>

<script>
import { mapActions } from 'vuex';
import BaseForm from '@forms/BaseForm.vue';
import ForgotPasswordForm from '@forms/ForgotPasswordForm.vue';

export default {
    props: {
        next: {
            type: String,
            default: '',
        },
    },

    data: () => ({
        formFields: [
            {
                name: 'email',
                type: 'email',
            },
            {
                name: 'password',
                type: 'password',
                required: true,
            },
        ],
        formErrors: {},
    }),

    methods: {
        ...mapActions('user', ['login']),

        async handleLogin(credentials) {
            const result = await this.login(credentials);
            if (!result.success) {
                this.formErrors = result.errors;
            } else {
                this.$emit('login-successful');
                this.$emit('close-modal');

                if (this.next.length) {
                    this.$router.push({
                        path: this.next,
                    });
                }
            }
        },

        forgotPassword() {
            this.launchModal({
                component: ForgotPasswordForm,
            });
            this.$emit('close-modal');
        },
    },

    components: {
        BaseForm,
    },
};
</script>
