import CompleteCurriculumProtocolResourceTier from '@fragment/shared/CompleteCurriculumProtocolResourceTier';
import CompleteRelatedTier from '@fragment/shared/CompleteRelatedTier';
import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteDownloadables from '@fragment/shared/CompleteDownloadables';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteCurriculumProtocolPage on CurriculumProtocolPage {
        id
        url
        title
        componentTypes
        audience
        protocolCategories: categories {
            id
            name
        }
        purpose
        materialsText: materials
        procedure
        variations
        references
        resources {
            ...CompleteCurriculumProtocolResourceTier
        }
        related {
            ...CompleteRelatedTier
        }
        thumbnailImage {
            ...CompleteCustomImage
        }
        parent {
            id
            title
            url
        }
        downloadables {
            ...CompleteDownloadables
        }
    }
    ${CompleteCurriculumProtocolResourceTier}
    ${CompleteRelatedTier}
    ${CompleteCustomImage}
    ${CompleteDownloadables}
`;
