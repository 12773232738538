export default {
    methods: {
        findObject({ obj = {}, key, value, returnAll = false, matchSubstring = null }) {
            const result = [];
            const recursiveSearch = (obj = {}) => {
                if (!obj || typeof obj !== 'object') {
                    return;
                }
                if (
                    obj[key] === value ||
                    (matchSubstring &&
                        obj[key] &&
                        typeof obj[key] === 'string' &&
                        value.includes(obj[key] + (matchSubstring.append || '')))
                ) {
                    result.push(obj);
                }
                Object.keys(obj).forEach(function (k) {
                    recursiveSearch(obj[k]);
                });
            };
            recursiveSearch(obj);
            return returnAll ? result : result[0];
        },
    },
};
