import gql from 'graphql-tag';

export default gql`
    fragment CompleteDocumentChooserBlock on DocumentChooserBlock {
        blockType
        id
        document {
            id
            file
            title
            url
        }
    }
`;
