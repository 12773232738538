<template>
    <div class="confirm-tou">
        <div class="big-body --bold">We have updated our terms and conditions</div>
        <div class="body">Kindly review and acknowledge in order to proceed.</div>
        <BaseForm
            :formUrl="formUrl"
            @submit-complete="onSubmitComplete"
            :initialValues="initialValues"
            submitLabel="Save and Continue"
        />
    </div>
</template>

<script>
import BaseForm from '@forms/BaseForm';

export default {
    data: () => ({
        formUrl: '/forms/terms-of-use-confirmation/',
    }),

    computed: {
        initialValues() {
            return {};
        },
    },

    methods: {
        onSubmitComplete(response) {
            if (response.status === 200 && response.redirected) {
                localStorage.setItem('touConfirmedAt', Date.now());
                this.$emit('close-modal');
            }
        },
    },

    components: {
        BaseForm,
    },
};
</script>
