import gql from 'graphql-tag';
import CompleteNavigationMenu from '@fragment/shared/CompleteNavigationMenu';

const removeTrailingSlash = (str) => {
    return str.replace(/\/$/, '');
};

export default {
    methods: {
        async getMenus(menu_id) {
            const { data } = await this.$apollo.query({
                query: gql`
                  query NavigationMenu {
                    NavigationMenu(menuId: "${menu_id}") {
                      ...CompleteNavigationMenu
                    }
                  }
                    ${CompleteNavigationMenu}
                `,
                prefetch: true,
            });
            return data?.NavigationMenu?.navigation || [];
        },
        generateMenuItems(menuBlocks) {
            return menuBlocks.map((menuBlock) => {
                const parentLink =
                    menuBlock.navSubMenuItems || menuBlock.navNestedSubMenuItems
                        ? menuBlock.title.link
                        : menuBlock.link;
                const parentIdentifier = parentLink?.text;
                const linkType = menuBlock.title?.linkType || menuBlock.linkType;
                const linkTo = menuBlock.title?.link?.linkTo || menuBlock.linkTo || menuBlock.link?.linkTo;
                const newWindow = menuBlock.title?.link?.newWindow || menuBlock.newWindow || menuBlock.link?.newWindow;

                return {
                    name: parentIdentifier,
                    id: parentIdentifier,
                    ...(parentLink?.linkTo && {
                        link: removeTrailingSlash(parentLink.page?.url || parentLink.customUrl),
                        linkType,
                        linkTo,
                        newWindow,
                    }),
                    ...(menuBlock.navSubMenuItems && {
                        subMenu: {
                            name: parentIdentifier,
                            id: parentIdentifier,
                            list: menuBlock.navSubMenuItems
                                .filter((subItem) => !!subItem?.link)
                                .map((subItem) => {
                                    return {
                                        name: subItem.link?.text,
                                        link: removeTrailingSlash(subItem.link?.page?.url || subItem.link?.customUrl),
                                        linkType: subItem.linkType,
                                        linkTo: subItem.linkTo || subItem.link?.linkTo,
                                        isSubItem: true,
                                        newWindow: subItem.newWindow || subItem.link?.newWindow,
                                    };
                                }),
                        },
                    }),
                    ...(menuBlock.navNestedSubMenuItems && {
                        nestedSubMenu: this.generateMenuItems(menuBlock.navNestedSubMenuItems),
                    }),
                };
            });
        },
    },
};
