<template>
    <div class="feedback-form">
        <BaseForm :formUrl="formUrl"> </BaseForm>
    </div>
</template>

<script>
import BaseForm from '@forms/BaseForm';
export default {
    data: () => ({
        formUrl: '/forms/feedback/',
    }),
    components: {
        BaseForm,
    },
};
</script>
