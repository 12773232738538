export default {
    computed: {
        allModifiers() {
            return this.modifier + ' ' + this.stateModifier;
        },
        modifierArray() {
            if (this.allModifiers.trim() !== '') {
                let modifiers = this.allModifiers;
                if (typeof modifiers === 'string') {
                    modifiers = modifiers.trim().split(',');
                    return modifiers.map((el) => '--' + el);
                }
                return modifiers.map((el) => '--' + el.replace(',', ' --'));
            } else {
                return '';
            }
        },
    },
    data() {
        return {
            stateModifier: '',
        };
    },
    props: {
        modifier: {
            type: [String, Array],
            default: '',
        },
    },
};
