import Vue from 'vue';

const onScrollEls = new Map();

function onScrollUpdateEl(el, cb) {
    if (typeof cb === 'function') {
        const details = {
            scrollX: window.scrollX,
            scrollY: window.scrollY,
            viewportHeight: window.innerHeight,
            viewportWidth: window.innerWidth,
            offsetTop: el.offsetTop,
            offsetHeight: el.offsetHeight,
            offsetWidth: el.offsetWidth,
            offsetLeft: el.offsetLeft,
        };
        cb(details);
    }
}

function handleOnScroll() {
    for (const [el, cb] of onScrollEls.entries()) {
        onScrollUpdateEl(el, cb);
    }
}

if (window) {
    window.addEventListener('scroll', handleOnScroll, false);
    handleOnScroll();
}

Vue.directive('on-scroll', {
    // When an element with a v-on-scroll directive is inserted into the dom, watch it and the function.
    inserted(el, { value }) {
        onScrollEls.set(el, value);
        onScrollUpdateEl(el, value);
    },
    update(el, { value }) {
        onScrollEls.set(el, value);
    },
    // When the element and it's children are updated, update momentum
    componentUpdated(el, { value }) {
        onScrollEls.set(el, value);
    },
    // When the element is removed from the dom, remove it from our list.
    unbind(el) {
        onScrollEls.delete(el);
        if (onScrollEls.size === 0) {
            window.removeEventListener('scroll', handleOnScroll, false);
        }
    },
});
