<template>
    <transition name="dropdown__content">
        <div v-show="active" class="dropdown__container" :style="computedStyles">
            <slot />
        </div>
    </transition>
</template>

<script>
export default {
    name: 'DropdownContent',
    inject: ['sharedState'],
    computed: {
        active() {
            return this.sharedState.active;
        },
        dropdownWidth() {
            return this.sharedState.dropdownWidth;
        },
        computedStyles() {
            if (this.dropdownWidth) {
                return {
                    'min-width': `${this.dropdownWidth}px`,
                };
            } else {
                return '';
            }
        },
    },
};
</script>
