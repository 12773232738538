import Vue from 'vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import LoginForm from '@forms/LoginForm';

export default (context, inject) => {
    if (!Vue.__site_user_mixin__) {
        Vue.__site_user_mixin__ = true;
        Vue.mixin({
            computed: {
                ...mapGetters('user', {
                    currentUser: 'currentUser',
                    user: 'currentUser',
                    userHasPremiumAccess: 'hasPremiumAccess',
                    subscribeLink: 'subscribeLink',
                    authToken: 'authToken',
                }),
                isUserAuthed() {
                    return !!this.user && !this.user.isGuest;
                },
            },

            methods: {
                ...mapMutations('user', ['launchLoginModal']),
                ...mapActions('user', ['upgradeAccount']),
            },
        });
    }

    inject('auth', {
        login: ({ next } = {}) => context.$modals.launch({ component: LoginForm, props: { next } }),
    });

    inject('user', {
        update: async () => await context.store.dispatch('user/getCurrentUser'),
        accountPath: '/account',
    });

    Vue.directive('requires-user', {
        bind(el, binding, vnode) {
            const { context } = vnode;
            const { value: requiresAuth } = binding;

            if (requiresAuth === false) {
                return;
            }

            const onClick = (evnt) => {
                if (!context.isUserAuthed) {
                    context.$auth.login();
                    evnt.stopPropagation();
                    evnt.preventDefault();
                }
            };

            el.__onClickRequiresUser__ = onClick;

            el.addEventListener('click', onClick, true);
        },
        unbind(el) {
            if (el.__onClickRequiresUser__) {
                el.removeEventListener('click', el.__onClickRequiresUser__, true);
            }
        },
    });

    if (process.client) {
        context.store.dispatch('user/getCurrentUser');
    }
};
