import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import gql from 'graphql-tag'

export default gql`
    fragment CompleteWebsiteBlock on WebsiteBlock {
        blockType
        name
        url
        description
        thumbnailImage {
            ...CompleteCustomImage
        }
    }
    ${CompleteCustomImage}
`;
