import Vue from 'vue';
import VueGtag from 'vue-gtag';

export default ({ $config, app }) => {
    if (!$config.GTAG_ID) {
        return;
    }
    Vue.use(
        VueGtag,
        {
            config: {
                id: `${$config.GTAG_ID}`,
            },
        },
        app.router,
    );
};