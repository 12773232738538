import CompleteCustomImage from '@fragment/shared/CompleteCustomImage';
import CompleteLocation from '@fragment/shared/CompleteLocation';
import CompleteDocumentBlock from '@fragment/shared/CompleteDocumentBlock';
import CompleteMarketingPhysicalLocationBlock from '@fragment/shared/CompleteMarketingPhysicalLocationBlock';
import CompleteMarketingEventVirtualLocationBlock from '@fragment/shared/CompleteMarketingEventVirtualLocationBlock';
import CurriculumLessonPagePreview from '@fragment/previews/CurriculumLessonPagePreview';
import gql from 'graphql-tag';

export default gql`
    fragment PagePreview on PageInterface {
        __typename
        urlPath
        url
        id
        pageType
        title
        firstPublishedAt
        lastPublishedAt
        parent {
            id
            title
            url
            urlPath
            pageType
        }
        ... on PLCatalogEntry {
            type
            description
            topics {
                name
            }
            upcomingPlEventsCount
        }
        ... on School {
            hasProfilePage
            portfolioWebsite
            logo {
                ...CompleteCustomImage
            }
            location {
                ...CompleteLocation
            }
            portfolioThumbnailImage {
                ...CompleteCustomImage
            }
            status {
                id
                name
            }
        }
        ... on Resource {
            thumbnailImage {
                ...CompleteCustomImage
            }
            subtitle
            description
            resourceTypes {
                id
                name
            }
        }
        ... on AnnualReport {
            title
            subtitle
            thumbnailImage {
                ...CompleteCustomImage
            }
            externalLink {
                ... on LinkBlockCustom {
                    blockType
                    linkTo
                    newWindow
                    customUrl
                    text
                }
            }
            documents {
                ...CompleteDocumentBlock
            }
        }
        ... on BlogPost {
            subtitle
            categories {
                id
                name
            }
            thumbnailImage {
                ...CompleteCustomImage
            }
        }
        ... on PressReleaseBlogPost {
            subtitle
            categories {
                id
                name
            }
            thumbnailImage {
                ...CompleteCustomImage
            }
        }
        ... on InTheNewsBlogPost {
            subtitle
            categories {
                id
                name
            }
            sourceLink
            sourceLogo {
                ...CompleteCustomImage
            }
            sourceTitle
            thumbnailImage {
                ...CompleteCustomImage
            }
        }
        ... on Award {
            year
            acceptingSubmissions
            submissionsOpen
            submissionsClose
            thumbnailImage {
                ...CompleteCustomImage
            }
        }
        ... on Study {
            type
            thumbnailImage {
                ...CompleteCustomImage
            }
        }
        ... on CorePractice {
            descriptionEn
            sequence
            # FIXME: Waiting for feedback as to whether or not this is needed
            # hero {
            #   ...PreviewHeroTier
            # }
            corePracticeDomain {
                id
                title
            }
        }
        ... on CorePracticeDomain {
            descriptionEn
            sequence
        }
        ... on Person {
            firstName
            lastName
            department
            schoolName
            hasBioPage
            url
            photo {
                ...CompleteCustomImage
            }
            contactPageUrl
            types
            titles
            spokespersonTopics {
                name
            }
        }
        ... on MarketingEvent {
            title
            subtitle
            startDate
            endDate
            startTime
            endTime
            description
            timezone
            hasPassed
            eventLocation {
                ...CompleteMarketingPhysicalLocationBlock
                ...CompleteMarketingEventVirtualLocationBlock
            }
            thumbnailImage {
                ...CompleteCustomImage
            }
        }
        ... on CustomForm {
            formUrl
            isModal
        }
        ... on CurriculumLessonPage {
            ...CurriculumLessonPagePreview
        }
        ... on CurriculumUnitPage {
            gradePage {
                id
                title
                url
            }
            componentType
            moduleNumber
            unitNumber
            subtitle
            lessons {
                ...CurriculumLessonPagePreview
            }
        }
    }
    ${CompleteCustomImage}
    ${CompleteLocation}
    ${CompleteDocumentBlock}
    ${CompleteMarketingPhysicalLocationBlock}
    ${CompleteMarketingEventVirtualLocationBlock}
    ${CurriculumLessonPagePreview}
`;
