import gql from 'graphql-tag';

export default gql`
    fragment CompletePDFFlipbookBlock on PDFFlipbookBlock {
        blockType
        id
        title
        embedCode
    }
`;
