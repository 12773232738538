import Vue from 'vue';
import VueMq from 'vue-mq';

Vue.use(VueMq, {
    breakpoints: {
        // default breakpoints - customize this
        s: 414,
        m: 760,
        l: 1024,
        xl: 1280,
        xxl: 1440,
    },
    defaultBreakpoint: 's', // customize this for SSR
});
