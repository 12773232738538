import gql from 'graphql-tag'

export default gql`
    fragment CompleteDownloadables on SectionDownloadablesObjectType {
        sectionTitle
        downloadables {
            type
            id
            title
            description
        }
        subsections {
            sectionTitle
            downloadables {
                type
                id
                title
                description
            }
        }
    }
`;
