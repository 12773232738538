import CompleteNavigationItemBlock from '@fragment/shared/CompleteNavigationItemBlock';
import CompleteNavigationSubMenuBlock from '@fragment/shared/CompleteNavigationSubMenuBlock';

import gql from 'graphql-tag';

export default gql`
    fragment CompleteNavigationNestedSubMenuBlock on NavigationNestedSubMenuBlock {
        title {
            ...CompleteNavigationItemBlock
        }
        navNestedSubMenuItems {
            ...CompleteNavigationSubMenuBlock
        }
    }
    ${CompleteNavigationSubMenuBlock}
    ${CompleteNavigationItemBlock}
`;
