import gql from 'graphql-tag';

export default gql`
    fragment CompleteLocation on Location {
        address1
        address2
        city
        state
        latitude
        longitude
        postalCode
    }
`;
