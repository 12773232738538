import CompleteNavigationItemBlock from '@fragment/shared/CompleteNavigationItemBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteNavigationSubMenuBlock on NavigationSubMenuBlock {
        title {
            ...CompleteNavigationItemBlock
        }
        navSubMenuItems {
            ...CompleteNavigationItemBlock
        }
    }
    ${CompleteNavigationItemBlock}
`;
