import CompletePageChooserBlock from '@fragment/shared/CompletePageChooserBlock';
import CompleteCurriculumMaterialChooserBlock from '@fragment/shared/CompleteCurriculumMaterialChooserBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumProtocolResourceTier on CurriculumProtocolResourceTier {
        header
        content
        resources {
            ...CompletePageChooserBlock
            ...CompleteCurriculumMaterialChooserBlock
        }
    }
    ${CompletePageChooserBlock}
    ${CompleteCurriculumMaterialChooserBlock}
`;
