<template>
    <header
        id="site-header"
        class="site-header"
        ref="siteHeader"
        :class="{
            '--reveal': stickyReveal, // Hide/reveal on scroll
            '--open': navOpen, // Is the nav open on mobile
            '--full': navFull, // Is the nav open full screen for My Account/Search
            '--bottom-open': isMenuOpen(primaryMenuItems), // Bottom sub-menu
            '--search': isSearchToggled, // Is search open
        }"
    >
        <nav aria-label="Control Bar Navigation" class="site-header__control-bar invert">
            <div class="site-header__wrapper">
                <ul
                    class="site-header__list"
                    :class="[
                        isMenuOpen(utilityMenuItems) ? '--sub-open' : '',
                        isItemActive(utilityMenuItems) ? '--active' : '',
                    ]"
                >
                    <li
                        v-for="(item, index) in utilityMenuItems"
                        :key="index"
                        :class="isItemActive(item) ? '--active' : ''"
                        class="site-header__item site-header__primary-item site-header__utility-item"
                    >
                        <!-- the v-if is because the button for My Account is handled one-off on desktop and we don't want to render it twice -->
                        <div
                            v-if="
                                isloggedInMobile ||
                                (item.id !== 'My Account' && !item.nestedSubMenu && !isExternalLink(item))
                            "
                            @click="handleHeaderItemClick($event, item)"
                            :class="isItemActive(item) ? '--active' : ''"
                            :key="index"
                            :id="`${item.id}-tab`"
                            class="site-header__link site-header__utility-link button-text"
                        >
                            <nuxt-link v-if="item.link && !isMobileHeader" :to="item.link">
                                {{ item.name }}
                            </nuxt-link>

                            <template v-if="isMobileHeader">
                                <template v-if="item.subMenu?.list?.length || item.nestedSubMenu">
                                    {{ item.name }}
                                    <Icon icon="forward" class="site-header__link__forward" />
                                </template>
                                <nuxt-link v-else :to="item.link">
                                    {{ item.name }}
                                </nuxt-link>
                            </template>
                        </div>

                        <!-- generates external link -->
                        <div
                            v-if="isExternalLink(item)"
                            :key="index"
                            :id="`${item.id}-tab`"
                            class="site-header__link site-header__utility-link button-text"
                        >
                            <a :href="item.link" target="_blank">{{ item.name }}</a>
                        </div>

                        <!-- generates dropdown style menu item -->
                        <template v-if="item.nestedSubMenu">
                            <div
                                v-if="!isMobileHeader"
                                :class="isItemActive(item) ? '--active' : ''"
                                :key="index"
                                :id="`${item.id}-tab`"
                                class="site-header__link site-header__utility-link button-text"
                            >
                                <Dropdown
                                    v-if="!isMobileHeader"
                                    modifier="utility-nav"
                                    ref="dropdownMenu"
                                    :ref-id="`${item.id}-dropdown`"
                                >
                                    <template v-slot:toggler>{{ item.name }}</template>
                                    <DropdownContent>
                                        <div
                                            v-for="(menuItem, index) in item.nestedSubMenu"
                                            @click="handleHeaderItemClick($event, menuItem, `${item.id}-dropdown`)"
                                            :key="index"
                                        >
                                            <DropdownLink :link="menuItem.link">
                                                {{ menuItem.name }}
                                            </DropdownLink>
                                        </div>
                                    </DropdownContent>
                                </Dropdown>
                            </div>
                            <div
                                v-if="isMobileHeader && !isloggedInMobile && !isExternalLink(item)"
                                @click="handleHeaderItemClick($event, item)"
                                :class="isItemActive(item) ? '--active' : ''"
                                :id="`${item.id}-tab`"
                                class="site-header__link site-header__utility-link button-text"
                            >
                                {{ item.name }}
                                <Icon icon="forward" class="site-header__link__forward" />
                            </div>

                            <!-- generates external link -->
                            <div
                                v-if="isExternalLink(item)"
                                :key="index"
                                :id="`${item.id}-tab`"
                                class="site-header__link site-header__utility-link button-text"
                            >
                                <a :href="item.link" target="_blank">{{ item.name }}</a>
                            </div>
                        </template>

                        <!-- generates normal subnavigation -->
                        <div
                            v-if="item.subMenu && item.subMenu.list.length"
                            class="site-header__sub-control-bar site-header__sub-bar invert"
                        >
                            <div class="site-header__wrapper">
                                <ul
                                    v-if="item.subMenu && item.subMenu.list.length"
                                    v-show="isSubMenuActive(item, utilityMenuItems)"
                                    class="site-header__sub-list"
                                    :id="`utility-panel-${index}`"
                                    :aria-labelledby="`${item.id}-tab`"
                                    :class="[
                                        isItemActive(item.subMenu) ? '--active' : '',
                                        isItemSelected(item.subMenu) ? '--selected' : '',
                                        item.id === 'My Account' ? 'site-header__account-list' : '',
                                    ]"
                                >
                                    <li
                                        v-if="isMobileHeader"
                                        :class="[
                                            isItemActive(item) ? '--active' : '',
                                            isItemSelected(item) ? '--selected' : '',
                                        ]"
                                        class="site-header__sub-item site-header__item site-header__sub-item--primary"
                                    >
                                        <button
                                            class="site-header__link site-header__back button-text"
                                            @click="handleBackClick($event, item)"
                                        >
                                            <Icon icon="back" class="site-header__link__back" />
                                        </button>

                                        <span class="site-header__link big-body">
                                            <nuxt-link v-if="item.link" :to="item.link">
                                                {{ item.name }}
                                            </nuxt-link>
                                            <template v-else>
                                                {{ item.name }}
                                            </template>
                                        </span>
                                    </li>
                                    <template v-for="(subMenuItem, index) in item.subMenu.list">
                                        <li
                                            v-if="!isExternalLink(subMenuItem)"
                                            :key="index"
                                            @click="handleHeaderItemClick($event, item)"
                                            :class="[
                                                isItemActive(subMenuItem) ? '--active' : '',
                                                isItemSelected(subMenuItem) ? '--selected' : '',
                                            ]"
                                            class="site-header__sub-item site-header__item"
                                        >
                                            <nuxt-link
                                                class="site-header__link button-text"
                                                :to="subMenuItem.link"
                                                :class="isItemActive(subMenuItem) ? '--active' : ''"
                                            >
                                                {{ subMenuItem.name }}
                                            </nuxt-link>
                                        </li>
                                        <li v-else class="site-header__sub-item site-header__item" :key="index">
                                            <a
                                                :href="subMenuItem.link"
                                                class="site-header__link button-text"
                                                target="_blank"
                                                >{{ subMenuItem.name }}</a
                                            >
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>

                        <!-- generates dropdown menu subnavigation items -->
                        <template v-if="item.nestedSubMenu">
                            <!-- generates mobile only menu listing all dropdown items -->
                            <div
                                class="site-header__sub-control-bar site-header__sub-bar invert"
                                v-if="isDropdownMenuActive(item, utilityMenuItems) && isMobileHeader"
                            >
                                <div class="site-header__wrapper">
                                    <ul
                                        class="site-header__sub-list"
                                        :aria-labelledby="`${item.id}-tab`"
                                        :class="[
                                            isItemActive(item.subMenu) ? '--active' : '',
                                            isItemSelected(item.subMenu) ? '--selected' : '',
                                        ]"
                                    >
                                        <li
                                            v-if="isMobileHeader"
                                            :class="[
                                                isItemActive(item) ? '--active' : '',
                                                isItemSelected(item) ? '--selected' : '',
                                            ]"
                                            class="site-header__sub-item site-header__item site-header__sub-item--primary"
                                        >
                                            <button
                                                class="site-header__link site-header__back button-text"
                                                @click="handleBackClick($event, item)"
                                            >
                                                <Icon icon="back" class="site-header__link__back" />
                                            </button>

                                            <span
                                                class="site-header__link big-body"
                                                @click="handleHeaderItemClick($event, item)"
                                            >
                                                {{ item.name }}
                                            </span>
                                        </li>
                                        <template v-for="(menuItem, index) in item.nestedSubMenu">
                                            <li
                                                v-if="!isExternalLink(menuItem)"
                                                :key="`subMenu-${index}`"
                                                @click="handleHeaderItemClick($event, menuItem)"
                                                :class="[
                                                    isItemActive(menuItem) ? '--active' : '',
                                                    isItemSelected(menuItem) ? '--selected' : '',
                                                ]"
                                                class="site-header__sub-item site-header__item"
                                            >
                                                <nuxt-link
                                                    class="site-header__link button-text"
                                                    :to="menuItem.link"
                                                    :class="isItemActive(menuItem) ? '--active' : ''"
                                                >
                                                    {{ menuItem.name }}
                                                </nuxt-link>
                                            </li>

                                            <li
                                                v-else
                                                :key="`subMenu-${index}`"
                                                class="site-header__sub-item site-header__item"
                                            >
                                                <a
                                                    :href="item.link"
                                                    class="site-header__link button-text"
                                                    target="_blank"
                                                    >{{ item.name }}</a
                                                >
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                            </div>

                            <!-- generates dropdown submenus only if submenus have items -->
                            <template v-for="(menuItem, index) in item.nestedSubMenu">
                                <div
                                    v-if="menuItem.subMenu.list.length"
                                    :key="`subMenu-${index}`"
                                    class="site-header__sub-control-bar site-header__sub-bar invert"
                                    v-show="isDropdownMenuActive(menuItem, utilityMenuItems)"
                                >
                                    <div class="site-header__wrapper">
                                        <ul
                                            class="site-header__sub-list"
                                            :id="`utility-panel-${index}`"
                                            :aria-labelledby="`${menuItem.id}-tab`"
                                            :class="[
                                                isItemActive(menuItem.subMenu) ? '--active' : '',
                                                isItemSelected(menuItem.subMenu) ? '--selected' : '',
                                            ]"
                                        >
                                            <li
                                                v-if="isMobileHeader"
                                                :class="[
                                                    isItemActive(menuItem) ? '--active' : '',
                                                    isItemSelected(menuItem) ? '--selected' : '',
                                                ]"
                                                class="site-header__sub-item site-header__item site-header__sub-item--primary"
                                            >
                                                <button
                                                    class="site-header__link site-header__back button-text"
                                                    @click="handleDropdownHeaderItemBackClick($event, item)"
                                                >
                                                    <Icon icon="back" class="site-header__link__back" />
                                                </button>

                                                <span class="site-header__link big-body">
                                                    <nuxt-link v-if="menuItem.link" :to="menuItem.link">
                                                        {{ menuItem.name }}
                                                    </nuxt-link>
                                                    <template v-else>
                                                        {{ menuItem.name }}
                                                    </template>
                                                </span>
                                            </li>

                                            <li
                                                v-else
                                                @click="handleHeaderItemClick($event, menuItem)"
                                                :class="[
                                                    isItemActive(menuItem) ? '--active' : '',
                                                    isItemSelected(menuItem) ? '--selected' : '',
                                                ]"
                                                class="site-header__sub-item site-header__item --has-separator"
                                            >
                                                <nuxt-link
                                                    class="site-header__link button-text"
                                                    :to="menuItem.link"
                                                    :class="isItemActive(menuItem) ? '--active' : ''"
                                                >
                                                    {{ menuItem.name }}
                                                </nuxt-link>
                                            </li>

                                            <template v-for="(subMenuItem, index) in menuItem.subMenu.list">
                                                <li
                                                    v-if="!isExternalLink(subMenuItem)"
                                                    :key="index"
                                                    @click="handleHeaderItemClick($event, subMenuItem)"
                                                    :class="[
                                                        isItemActive(subMenuItem) ? '--active' : '',
                                                        isItemSelected(subMenuItem) ? '--selected' : '',
                                                    ]"
                                                    class="site-header__sub-item site-header__item"
                                                >
                                                    <nuxt-link
                                                        class="site-header__link button-text"
                                                        :to="subMenuItem.link"
                                                        :class="isItemActive(subMenuItem) ? '--active' : ''"
                                                    >
                                                        {{ subMenuItem.name }}
                                                    </nuxt-link>
                                                </li>

                                                <li v-else :key="index" class="site-header__sub-item site-header__item">
                                                    <a
                                                        :href="subMenuItem.link"
                                                        class="site-header__link button-text"
                                                        target="_blank"
                                                        >{{ subMenuItem.name }}</a
                                                    >
                                                </li>
                                            </template>
                                        </ul>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </li>
                </ul>
                <div class="site-header__list site-header__tools" :class="{ icons: $mq === 's' }">
                    <nuxt-link to="/" class="site-header__mobile-logo">
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 104 104"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 0V104L40.4029 65H13.4676V13H53.8705V25.6389H28.0576V32.5H52.3741V44.9583H28.0576V52H53.8705V91H94.2734V78H68.4604V37.9167L107.741 0H0Z"
                                fill="currentColor"
                            />
                        </svg>
                    </nuxt-link>

                    <div class="site-header__account">
                        <ul
                            v-if="userIsAuthed"
                            class=""
                            :class="[
                                isMenuOpen(utilityMenuItems) ? '--sub-open' : '',
                                isItemActive(utilityMenuItems) ? '--active' : '',
                            ]"
                        >
                            <template>
                                <li
                                    :class="isItemActive(myAccountSubNav) ? '--active' : ''"
                                    class="site-header__item"
                                    @click="handleHeaderItemClick($event, myAccountSubNav)"
                                >
                                    <nuxt-link
                                        class="site-header__account-link button-text site-header__my-account-text"
                                        :to="$user.accountPath"
                                    >
                                        <Icon icon="account" class="site-header__my-account-icon" />
                                        <template v-if="$mq !== 's'">My Account</template>
                                    </nuxt-link>
                                </li>
                            </template>
                        </ul>
                        <template v-else>
                            <button
                                @click="$auth.login({ next: $user.accountPath })"
                                class="site-header__account-link button-text"
                            >
                                <Icon v-if="$mq === 's'" icon="account" />
                                <template v-else>Sign In</template>
                            </button>

                            <template v-if="$mq !== 's'">
                                /
                                <nuxt-link to="/create-an-account" class="site-header__account-link button-text"
                                    >Create Account</nuxt-link
                                >
                            </template>
                        </template>
                    </div>

                    <form class="site-header__search-group" @submit.prevent="doSearch">
                        <label for="search">
                            <template v-if="isMobileHeader">
                                <nuxt-link to="/search"
                                    ><Icon icon="search" /><span class="hidden">Search</span></nuxt-link
                                >
                            </template>
                            <template v-else>
                                <Icon icon="search" /><span class="hidden">Search</span>
                            </template> </label
                        ><input
                            type="search"
                            id="search"
                            class="site-header__search"
                            name="search"
                            placeholder="Search"
                            @focus="!isSearchToggled && toggleSearchOn()"
                            @blur="isSearchToggled && toggleSearchOff()"
                            v-model="search"
                        />
                    </form>
                    <button @click="toggleMenu()" class="site-header__mobile-menu-close">
                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.25472 8.81299L21.6291 21.1874"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="square"
                            />
                            <path
                                d="M21.6291 8.81264L9.25472 21.187"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-miterlimit="10"
                                stroke-linecap="square"
                            />
                        </svg>
                        <span class="hidden-text">Close Menu</span>
                    </button>
                </div>
            </div>
        </nav>

        <nav aria-label="Main Navigation" class="site-header__main">
            <div class="site-header__wrapper">
                <nuxt-link to="/" class="site-header__logo">
                    <img
                        class="site-header__logo-img site-header__logo-mark"
                        src="~/assets/images/logo-mark.svg"
                        alt="EL Education"
                    />
                    <img
                        class="site-header__logo-img site-header__logo-text"
                        src="~/assets/images/logo-type.svg"
                        alt=""
                        aria-hidden="true"
                    />
                </nuxt-link>

                <ul
                    class="site-header__list"
                    :class="[
                        isMenuOpen(primaryMenuItems) ? '--sub-open' : '',
                        isItemActive(primaryMenuItems) ? '--active' : '',
                    ]"
                >
                    <li
                        v-for="(item, index) in primaryMenuItems"
                        :key="index"
                        :class="isItemActive(item) ? '--active' : ''"
                        class="site-header__item site-header__primary-item"
                    >
                        <div
                            v-if="!isExternalLink(item)"
                            @click="handleHeaderItemClick($event, item)"
                            :class="isItemActive(item) ? '--active' : ''"
                            :id="`${item.id}-tab`"
                            class="site-header__link site-header__main-link heavy-button-text"
                        >
                            <nuxt-link
                                v-if="item.link && (!isMobileHeader || (item.subMenu && !item.subMenu.list.length))"
                                :to="item.link"
                            >
                                {{ item.name }}
                            </nuxt-link>
                            <template v-else>
                                {{ item.name }}
                                <Icon icon="forward" class="site-header__link__forward" />
                            </template>
                        </div>

                        <div
                            v-if="isExternalLink(item)"
                            :id="`${item.id}-tab`"
                            class="site-header__link site-header__main-link heavy-button-text"
                        >
                            <a :href="item.link" target="_blank">{{ item.name }}</a>
                        </div>

                        <div
                            v-if="item.subMenu && item.subMenu.list.length"
                            class="site-header__sub-main site-header__sub-bar"
                        >
                            <div class="site-header__wrapper">
                                <ul
                                    v-if="item.subMenu && item.subMenu.list.length"
                                    class="site-header__sub-list"
                                    v-show="isSubMenuActive(item, primaryMenuItems)"
                                    :id="`primary-panel-${index}`"
                                    :aria-labelledby="`${item.id}-tab`"
                                    :class="[
                                        isItemActive(item.subMenu) ? '--active' : '',
                                        isItemSelected(item.subMenu) ? '--selected' : '',
                                    ]"
                                >
                                    <li
                                        v-if="isMobileHeader"
                                        :class="[
                                            isItemActive(item) ? '--active' : '',
                                            isItemSelected(item) ? '--selected' : '',
                                        ]"
                                        class="site-header__sub-item site-header__item site-header__sub-item--primary"
                                    >
                                        <button
                                            class="site-header__link site-header__back button-text"
                                            @click="handleBackClick($event, item)"
                                        >
                                            <Icon icon="back" class="site-header__link__back" />
                                        </button>

                                        <span class="site-header__link big-body">
                                            <nuxt-link v-if="item.link" :to="item.link">
                                                {{ item.name }}
                                            </nuxt-link>
                                            <template v-else>
                                                {{ item.name }}
                                            </template>
                                        </span>
                                    </li>

                                    <template v-for="(subMenuItem, index) in item.subMenu.list">
                                        <li
                                            v-if="!isExternalLink(subMenuItem)"
                                            :key="index"
                                            @click="handleHeaderItemClick($event, item)"
                                            :class="[
                                                isItemActive(subMenuItem) ? '--active' : '',
                                                isItemSelected(subMenuItem) ? '--selected' : '',
                                            ]"
                                            class="site-header__sub-item site-header__item"
                                        >
                                            <nuxt-link
                                                class="site-header__link button-text"
                                                :to="subMenuItem.link || ''"
                                                >{{ subMenuItem.name }}</nuxt-link
                                            >
                                        </li>

                                        <li v-else :key="index" class="site-header__sub-item site-header__item">
                                            <a
                                                :href="subMenuItem.link"
                                                target="_blank"
                                                class="site-header__link button-text"
                                                >{{ subMenuItem.name }}</a
                                            >
                                        </li>
                                    </template>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>

                <div class="site-header__cta header-ctas button-group">
                    <template v-for="(item, index) in bottomCtas">
                        <ButtonComponent
                            v-if="item.link && item.linkType === 'primary'"
                            :key="`button-${index}`"
                            :destination="item.link"
                            modifier="crimson"
                            class="partner-button"
                        >
                            {{ item.name }}
                        </ButtonComponent>

                        <Link
                            v-else-if="item.link && item.linkType === 'secondary'"
                            :key="`link-${index}`"
                            :destination="item.link"
                            class="header-ctas__cta site-header__link"
                        >
                            <span class="heavy-button-text --body --crimson">
                                {{ item.name }}
                            </span>
                        </Link>
                    </template>
                </div>
                <button @click="toggleMenu()" class="site-header__mobile-menu-open">
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1.01514 10H19.3485"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="square"
                        />
                        <path
                            d="M1.01514 4.1665H19.3485"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="square"
                        />
                        <path
                            d="M1.01514 15.833H10.1818"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-miterlimit="10"
                            stroke-linecap="square"
                        />
                    </svg>
                    <span class="hidden-text">Open Menu</span>
                </button>
            </div>
        </nav>
    </header>
</template>

<script>
import menus from '@mixin/menus';
import arrays from '@mixin/arrays';
import Link from '@module/Link';
import ButtonComponent from '@module/ButtonComponent';
import Icon from '@module/Icon';

import Dropdown from '@module/Dropdown';
import DropdownContent from '@module/DropdownContent';
import DropdownLink from '@module/DropdownLink';

const MY_ACCOUNT = {
    id: 'My Account',
    link: '/account',
    linkType: 'default',
    name: 'My Account',
    subMenu: {
        id: 'My Account',
        list: [
            {
                name: 'Saved Resources',
                link: '/account/user-folders/resources',
                linkType: 'default',
                isSubItem: true,
                isSelected: false,
            },
            {
                name: 'Saved Protocols',
                link: '/account/user-folders/protocols',
                linkType: 'default',
                isSubItem: true,
                isSelected: false,
            },
            {
                name: 'Account Details',
                link: '/account/details',
                linkType: 'default',
                isSubItem: true,
                isSelected: false,
            },
            {
                name: 'Log Out',
                link: '/log-out',
                linkType: 'default',
                isSubItem: true,
                isSelected: false,
            },
        ],
    },
    isSelected: false,
};

export default {
    name: 'SiteHeader',
    components: {
        Icon,
        ButtonComponent,
        Link,
        Dropdown,
        DropdownContent,
        DropdownLink,
    },
    mixins: [menus, arrays],
    data() {
        return {
            isSearchToggled: false,
            navFull: false,
            stickyReveal: false,
            navOpen: false,
            scrollPosition: process.client ? window.scrollY : 0,
            utilityMenuItems: [],
            primaryMenuItems: [],
            myAccountSubNav: MY_ACCOUNT,
            bottomCtas: [],
            search: '',
        };
    },
    computed: {
        userIsAuthed() {
            return this.isUserAuthed || false;
        },
        isMobileHeader() {
            return this.$mq === 's' || this.$mq === 'm' || this.$mq === 'l';
        },
        isloggedInMobile() {
            return (this.isMobileHeader && this.userIsAuthed) || false;
        },
        menus() {
            return [this.utilityMenuItems, this.primaryMenuItems];
        },
    },
    watch: {
        stickyReveal: {
            handler(newVal, oldVal) {
                if (process.client) {
                    if (newVal !== oldVal) {
                        newVal
                            ? document.body.classList.add('nav--revealed')
                            : document.body.classList.remove('nav--revealed');
                    }
                }
            },
            immediate: true,
        },
        $route(newRoute) {
            this.updateSelectedItem(newRoute.path);
            this.closeMenu();
            document.body.style.setProperty('--nav-height', this.$refs.siteHeader.clientHeight);
        },
    },
    methods: {
        async setupMenus() {
            const menus = await this.getMenus('header');
            if (menus.length) {
                this.utilityMenuItems = [
                    ...this.mapMenuWithState(
                        this.generateMenuItems(
                            menus.find((menu) => menu.navigationMenuKey === 'site-utility').NavigationMenuBlock,
                        ),
                    ),
                    this.myAccountSubNav,
                ];
                this.primaryMenuItems = this.mapMenuWithState(
                    this.generateMenuItems(
                        menus.find((menu) => menu.navigationMenuKey === 'site-primary').NavigationMenuBlock,
                    ),
                );
                this.bottomCtas = this.primaryMenuItems.splice(-2);
                this.updateSelectedItem(this.$route.path);
            }
        },
        mapMenuWithState(menuItems) {
            return menuItems.map((item) => {
                return {
                    ...item,
                    isSelected: false,
                    ...(item.subMenu && {
                        subMenu: {
                            ...item.subMenu,
                            list: item.subMenu.list.map((item) => {
                                return {
                                    ...item,
                                    isSelected: false,
                                };
                            }),
                        },
                    }),
                    ...(item.nestedSubMenu && {
                        nestedSubMenu: this.mapMenuWithState(item.nestedSubMenu),
                    }),
                };
            });
        },
        updateSelectedItem(routePath) {
            let previousSelectedItems = this.findObject({
                obj: this.menus,
                key: 'isSelected',
                value: true,
                returnAll: true,
            });
            if (previousSelectedItems.length) {
                previousSelectedItems.forEach((item) => {
                    item.isSelected = false;
                });
            }
            routePath = routePath.replace(/\/$/, '');
            const parentRoutePath = routePath?.substr(0, routePath?.lastIndexOf('/'));
            if (routePath) {
                let currentSelectedItems = this.findObject({
                    obj: this.menus,
                    key: 'link',
                    value: routePath,
                    returnAll: true,
                });
                if (currentSelectedItems.length) {
                    currentSelectedItems.forEach((item) => {
                        item.isSelected = true;
                    });
                } else {
                    let parentSelectedItem = this.findObject({
                        obj: this.menus,
                        key: 'link',
                        value: parentRoutePath,
                        returnAll: true,
                    });
                    if (parentSelectedItem.length) {
                        parentSelectedItem.forEach((item) => {
                            item.isSelected = true;
                        });
                    }
                }
            }
        },
        scrollHandler() {
            // TODO tweak thresholds and transitions on this
            if (!this.navFull) {
                if (window.scrollY > this.scrollPosition) {
                    this.stickyReveal = false;
                    document.body.style.setProperty('--nav-height', this.$refs.siteHeader.clientHeight);
                }
                if (window.scrollY < this.scrollPosition) {
                    this.stickyReveal = true;
                    document.body.style.setProperty('--nav-height', this.$refs.siteHeader.clientHeight);
                }

                this.scrollPosition = window.scrollY;
            }

            document.body.classList.toggle('--scrolled', window.scrollY > 0);
        },
        toggleSearchOn() {
            this.isSearchToggled = true;
        },
        toggleSearchOff() {
            this.isSearchToggled = false;
        },
        toggleMenu() {
            if (this.navOpen) {
                this.navOpen = false;
            } else {
                this.navOpen = true;
            }
        },
        closeMenu() {
            this.navOpen = false;
        },
        isMenuOpen(menu) {
            return menu.some((item) => this.isSubMenuActive(item, menu));
        },
        isSubMenuActive(item) {
            const selectedItem = this.findObject({
                obj: item,
                key: 'isSelected',
                value: true,
            });
            return selectedItem?.subMenu?.list.length || selectedItem?.isSubItem;
        },
        isItemActive(item) {
            return this.findObject({
                obj: item,
                key: 'isSelected',
                value: true,
            });
        },
        isItemSelected(item) {
            return this.findObject({
                obj: item,
                key: 'isSelected',
                value: true,
            });
        },
        handleHeaderItemClick(e, item, dropdownID) {
            e.preventDefault();
            item.isSelected = true;
            const dropdown = this.$refs.dropdownMenu?.find((el) => el.$attrs['ref-id'] === dropdownID);
            dropdown?.handleClickAway();
        },
        handleBackClick(e, item) {
            if (this.isMobileHeader) {
                e.preventDefault();
                item.isSelected = false;
                item.nestedSubMenu?.forEach((item) => {
                    item.isSelected = false;
                });
                item.subMenu?.list.forEach((item) => {
                    item.isSelected = false;
                });
            }
        },
        handleDropdownHeaderItemBackClick(e, item) {
            if (this.isMobileHeader) {
                e.preventDefault();
                item.isSelected = true;
                item.nestedSubMenu?.forEach((item) => {
                    item.isSelected = false;
                    this.handleBackClick(e, item);
                });
            }
        },
        isDropdownMenuActive(item) {
            const selectedItem = this.findObject({
                obj: item,
                key: 'isSelected',
                value: true,
            });
            return selectedItem;
        },
        doSearch() {
            this.$router.push(`/search/?search=${this.search}&page=1`);
            this.search = '';
        },
        setNavHeight(entry) {
            document.body.style.setProperty('--nav-height', entry.target.clientHeight);
        },
        isExternalLink(item) {
            return (
                item.newWindow ||
                (item.linkTo === 'custom_url' && (item.link.startsWith('http') || item.link.startsWith('www.')))
            );
        },
    },
    async mounted() {
        await this.setupMenus();

        if (process.client) {
            this.navObserver = new ResizeObserver((entries) => {
                entries.forEach((entry) => {
                    this.setNavHeight(entry);
                });
            });
        }

        if (this.navObserver) {
            this.$nextTick(() => {
                this.navObserver.observe(this.$el);
            });
        }

        document.addEventListener('scroll', this.scrollHandler);
    },
    beforeDestroy() {
        document.removeEventListener('scroll', this.scrollHandler);
        if (this.navObserver) {
            this.navObserver.disconnect();
        }
    },
};
</script>
