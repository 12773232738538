import CompleteMarketingPhysicalLocationBlock from '@fragment/shared/CompleteMarketingPhysicalLocationBlock';
import CompleteMarketingEventVirtualLocationBlock from '@fragment/shared/CompleteMarketingEventVirtualLocationBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompletePLDate on PLDate {
        beginningDate
        beginningTime
        endDate
        endTime
        eventLocation {
            ...CompleteMarketingPhysicalLocationBlock
            ...CompleteMarketingEventVirtualLocationBlock
        }
        timezone
    }
    ${CompleteMarketingPhysicalLocationBlock}
    ${CompleteMarketingEventVirtualLocationBlock}
`;
