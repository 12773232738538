<template>
    <div
        ref="modalElement"
        class="site-modal"
        role="dialog"
        aria-modal="true"
        aria-labelledby="dialogTitle"
        aria-describedby="dialogDesc"
        tabindex="-1"
    >
        <slot />
    </div>
</template>

<script>
export default {
    data() {
        return {
            firstFocusItem: null,
            lastFocusItem: null,
        };
    },
    methods: {
        tabHandler(e) {
            let isTabPressed = e?.key === 'Tab';

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) {
                if (document.activeElement === this.firstFocusItem) {
                    this.lastFocusItem.focus();
                    e.preventDefault();
                }
            } else {
                if (document.activeElement === this.lastFocusItem) {
                    this.firstFocusItem.focus();
                    e.preventDefault();
                }
            }
        },
        setModalFocusTrap() {
            this.$nextTick(() => {
                const modal = this.$refs.modalElement;
                const initialFocusItem = modal?.querySelector('.--init-focus');
                const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

                const modalFocusItems = modal?.querySelectorAll(focusableElements);
                this.firstFocusItem = modalFocusItems?.[0];
                this.lastFocusItem = modalFocusItems?.[modalFocusItems.length - 1];

                modal?.focus();

                if (this.firstFocusItem !== this.lastFocusItem) {
                    if (initialFocusItem) {
                        initialFocusItem.focus();
                    } else {
                        this.firstFocusItem?.focus();
                    }
                    document.addEventListener('keydown', this.tabHandler);
                }
            });
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.setModalFocusTrap();
        });
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.tabHandler);
    },
};
</script>
