<template>
    <SiteNotice :actions="false">
        <template #message>
            <h5 class="big-body --bold">Please confirm email change</h5>
            <p>
                We notice you are trying to change your email address to {{ email }}. Please confirm with your password.
            </p>
            <BaseForm :fields="formFields" :errors="formErrors" @submit="handleSubmit">
                <template #actions>
                    <button class="button --crimson" type="submit">Submit</button>
                    <a class="button" @click="closeNotice">Cancel</a>
                </template>
            </BaseForm>
        </template>
    </SiteNotice>
</template>

<script>
import { mapActions } from 'vuex';
import SiteNotice from '@global/SiteNotice';
import BaseForm from '@forms/BaseForm';

export default {
    props: {
        email: {
            type: String,
        },
    },

    data() {
        return {
            formErrors: {},
        };
    },

    computed: {
        formFields: () => [
            {
                name: 'password',
                type: 'password',
            },
        ],
    },

    methods: {
        ...mapActions('user', ['updateEmail', 'showEmailVerificationNotice']),
        async handleSubmit({ password }) {
            const { success, errors } = await this.updateEmail({ email: this.email, password });
            if (success) {
                this.showEmailVerificationNotice(this.email);
            } else {
                this.formErrors = errors;
            }
        },

        closeNotice() {
            this.$emit('close-notice');
        },
    },

    components: {
        SiteNotice,
        BaseForm,
    },
};
</script>
