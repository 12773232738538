import CompletePaginatedSearchResults from '@fragment/shared/CompletePaginatedSearchResults';
import CompleteResourceSearchResultItem from '@fragment/shared/CompleteResourceSearchResultItem';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteResourceSearchResult on ResourcePaginatedSearchResults {
        ...CompletePaginatedSearchResults
        objects {
            ...CompleteResourceSearchResultItem
        }
    }
    ${CompletePaginatedSearchResults}
    ${CompleteResourceSearchResultItem}
`;
