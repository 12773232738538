import gql from 'graphql-tag';

export default gql`
    fragment CompletePaginatedSearchResults on PaginatedSearchResults {
        page
        pages
        hasNext
        hasPrev
        count
    }
`;
