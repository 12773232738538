import CompleteCurriculumMaterial from '@fragment/shared/CompleteCurriculumMaterial';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumMaterialChooserBlock on CurriculumMaterialChooserBlock {
        material {
            ...CompleteCurriculumMaterial
        }
        title
        notes
    }
    ${CompleteCurriculumMaterial}
`;
