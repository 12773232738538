<template>
    <BaseForm class="reset-password-form" :fields="formFields" :errors="formErrors" @submit="resetPassword">
        <template #title>
            <h5 class="big-body --bold">Enter your email to reset your password</h5>
        </template>
    </BaseForm>
</template>

<script>
import { mapActions } from 'vuex';
import BaseForm from '@forms/BaseForm.vue';

export default {
    data: () => ({
        formFields: [
            {
                name: 'email',
                label: 'Your Email',
                type: 'email',
            },
        ],
        formErrors: {},
    }),

    methods: {
        ...mapActions('user', ['sendPasswordResetEmail']),

        async resetPassword({ email }) {
            const result = await this.sendPasswordResetEmail(email);
            if (!result.success) {
                this.formErrors = result.errors;
            } else {
                this.$emit('close-modal');
                this.showNotice({
                    title: 'An email has been sent to you',
                    message: `We’ve sent an email to ${email} with a link to reset your password`,
                });
            }
        },
    },

    components: {
        BaseForm,
    },
};
</script>
