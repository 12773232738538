<template>
    <div class="button-component" :class="modifier" v-on="$listeners">
        <Link v-if="destination" :destination="destination" :modalForm="modalForm" :disabled="disabled" :aria-disabled="disabled">
            <div class="button button-text" :class="modifierArray">
                <slot />
                <Icon v-if="icon" :icon="icon" aria-hidden="true"/>
            </div>
        </Link>

        <template v-else>
            <button class="button button-text" :class="modifierArray" :disabled="disabled" :aria-disabled="disabled">
                <slot />
                <Icon v-if="icon" :icon="icon" :aria-label="icon"/>
            </button>
        </template>
    </div>
</template>

<script>
import Icon from '@module/Icon';
import modifier from '@mixin/modifier';
import Link from '@module/Link';

export default {
    props: {
        destination: {
            type: String,
        },
        icon: {
            type: String,
        },
        modifier: {
            type: [String, Array],
            default: '',
        },
        modalForm: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Icon,
        Link,
    },
    mixins: [modifier],
};
</script>
