import CompletePaginatedSearchResults from '@fragment/shared/CompletePaginatedSearchResults';
import CompleteCurriculumProtocolPage from '@fragment/pages/CompleteCurriculumProtocolPage';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumProtocolSearchResult on CurriculumProtocolPagePaginatedSearchResults {
        ...CompletePaginatedSearchResults
        objects {
            __typename
            ...CompleteCurriculumProtocolPage
        }
    }
    ${CompletePaginatedSearchResults}
    ${CompleteCurriculumProtocolPage}
`;
