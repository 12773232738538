import PagePreview from '@fragment/previews/PagePreview';
import gql from 'graphql-tag'

export default gql`
    fragment CompletePageChooserBlock on PageChooserBlock {
        blockType
        id
        page {
            ...PagePreview
        }
    }
    ${PagePreview}
`;
