import CompleteNavigationMenuBlock from '@fragment/shared/CompleteNavigationMenuBlock';
import gql from 'graphql-tag';

export default gql`
    fragment CompleteNavigationMenu on NavigationMenu {
        id: menuId
        menuId
        navigation {
            ...CompleteNavigationMenuBlock
        }
    }
    ${CompleteNavigationMenuBlock}
`;
