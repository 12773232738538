<template>
    <nuxt-link v-if="link && isInternalUrl(link)" :to="link" class="button__button-text" :class="modifierArray">
        <slot />
        <Icon v-if="icon" :icon="icon" />
    </nuxt-link>
    <a v-else-if="link" :href="link" class="button__button-text" :class="modifierArray">
        <slot />
        <Icon v-if="icon" :icon="icon" />
    </a>
    <a v-else v-on="$listeners" class="button__button-text" :class="modifierArray">
        <slot />
        <Icon v-if="icon" :icon="icon" />
    </a>
</template>

<script>
import Icon from '@module/Icon';
import modifier from '@mixin/modifier';
import links from '@mixin/links';

export default {
    props: {
        link: {
            type: String,
        },
        icon: {
            type: String,
        },
        modifier: {
            type: [String, Array],
            default: '',
        },
    },
    components: {
        Icon,
    },
    mixins: [links, modifier],
};
</script>
