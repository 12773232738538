<template>
    <div id="el-site" @keydown.tab="tabHandler">
        <div id="focus-reset" tabindex="0"></div>
        <a
            class="skip-link"
            ref="skipLink"
            href="#el-site-main"
            @keydown.enter="skipLinkHandler"
            @click="skipLinkHandler"
            >Skip to content</a
        >
        <SiteHeader v-if="isNavigationVisible" />
        <main id="el-site-main">
            <Nuxt></Nuxt>
        </main>
        <SiteFooter v-if="isNavigationVisible" />
        <SiteModals />
        <SiteFeedback />
    </div>
</template>

<script>
import SiteHeader from '@global/SiteHeader';
import SiteFooter from '@global/SiteFooter';
import SiteModals from '@global/SiteModals';
import SiteFeedback from '@global/SiteFeedback';
import { mapGetters } from 'vuex';

export default {
    name: 'App',
    components: {
        SiteHeader,
        SiteFooter,
        SiteModals,
        SiteFeedback,
    },
    data() {
        return {
            fromSkipLink: false,
            contentFocusItem: null,
        };
    },
    watch: {
        $route: {
            handler: function () {
                this.$nextTick(() => {
                    document.getElementById('focus-reset').focus();
                    this.fromSkipLink = false;
                    this.contentFocusItem = document
                        ?.getElementById('el-site-main')
                        ?.querySelectorAll(
                            'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])',
                        )[0];

                    document.body.style.overflow = 'visible';
                    document.body.style.height = 'auto';
                });
            },
            deep: true,
            immediate: false,
        },
    },
    methods: {
        skipLinkHandler() {
            this.fromSkipLink = true;
        },
        tabHandler() {
            if (this.fromSkipLink && this.contentFocusItem) {
                this.contentFocusItem.focus();
            }
            this.fromSkipLink = false;
        },
    },

    computed: {
        ...mapGetters('siteNavigation', ['isNavigationVisible']),
        curtainStyle() {
            const solid = this.modals.find((modal) => modal.solidCurtain) !== undefined;
            return solid ? '--solid' : '--translucent';
        },
    },
};
</script>
