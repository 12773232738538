import { IntrospectionFragmentMatcher, InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import possibleTypes from '../fragmentTypes.json';

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: possibleTypes,
});

export default function (context) {
    const errorLink = onError(({ graphQLErrors = [], networkError = {}, operation = {}, response = {} }) => {
        // Get the current date and time
        const currentDate = new Date();
        operation.query = 'setting null in apollo/index.js';
        response.data = 'setting null in apollo/index.js';
        console.log(
            JSON.stringify(
                {
                    msg: 'Error in apollo',
                    date: currentDate,
                    graphQLErrors,
                    networkError,
                    operation,
                    response,
                },
                null,
                2,
            ),
        );
        context.redirect('/404.html');
        return;
    });

    return {
        link: errorLink,
        httpEndpoint: `${context.$config.NODE_BACKEND_URL.replace(/\/$/, '')}/api/graphql/`,
        browserHttpEndpoint: `${context.$config.BACKEND_URL.replace(/\/$/, '')}/api/graphql/`,
        getAuth: () => {
            if (process.client) {
                return `JWT ${localStorage.getItem('token')}`;
            }
            return '';
        },
        cache: new InMemoryCache({
            fragmentMatcher,
        }),
    };
}
