import gql from 'graphql-tag';

export default gql`
    fragment CompleteCurriculumMaterial on CurriculumMaterial {
        id
        title
        fileName
        fileUrl
        materialType
        audience
    }
`;
