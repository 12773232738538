import CompleteResourceSearchResult from '@fragment/shared/CompleteResourceSearchResult';
import CompleteCurriculumProtocolSearchResult from '@fragment/shared/CompleteCurriculumProtocolSearchResult';
import gql from 'graphql-tag'

export default gql`
    fragment UserFolderPreview on UserFolder {
        id
        name
        allowedTypes
        itemsCount
        resources {
            __typename
            ...CompleteResourceSearchResult
        }
        protocols {
            __typename
            ...CompleteCurriculumProtocolSearchResult
        }
    }
    ${CompleteResourceSearchResult}
    ${CompleteCurriculumProtocolSearchResult}
`;
