import Vue from 'vue';

let momentumRoot = null;
const momentumEls = new Set();

function momentumUpdateEl(el) {
    el.style.setProperty('--width', el.offsetWidth);
    el.style.setProperty('--height', el.offsetHeight);
    el.style.setProperty('--offset-top', el.offsetTop);
    // el.style.setProperty('--left', el.offsetLeft)
    // e.style.setProperty('--random',Math.random());
}

function momentumInit() {
    momentumRoot.setProperty('--viewport-height', window.innerHeight);
    for (let e of momentumEls.values()) {
        momentumUpdateEl(e);
    }
}

function momentumScroll() {
    if (momentumRoot) {
        momentumRoot.setProperty('--scroll-depth', window.scrollY);
        //momentumRoot.setProperty('--scrollx',window.scrollX);
        //momentumRoot.setProperty('--scrolly',window.scrollY);
    }
    for (let e of momentumEls.values()) {
        momentumUpdateEl(e);
    }
}

if (window) {
    momentumRoot = document.body.style;
    window.addEventListener('resize', momentumInit);
    window.addEventListener('scroll', momentumScroll, false);
    momentumInit();
    momentumScroll();
}

Vue.directive('momentum', {
    // When an element with a v-momentum directive is inserted into the dom, watch it.
    inserted(el) {
        momentumEls.add(el);
        momentumUpdateEl(el);
    },
    // When the element and it's children are updated, update momentum
    componentUpdated(el) {
        momentumUpdateEl(el);
    },
    // When the element is removed from the dom, remove it from our list.
    unbind(el) {
        momentumEls.delete(el);
    },
});
