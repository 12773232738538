import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _59cb6658 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _5646c629 = () => interopDefault(import('../pages/log-out.vue' /* webpackChunkName: "pages/log-out" */))
const _d53482ae = () => interopDefault(import('../pages/stripe-callback.vue' /* webpackChunkName: "pages/stripe-callback" */))
const _29162878 = () => interopDefault(import('../pages/account/details.vue' /* webpackChunkName: "pages/account/details" */))
const _1ae15b65 = () => interopDefault(import('../pages/account/user-folders/_contentType/index.vue' /* webpackChunkName: "pages/account/user-folders/_contentType/index" */))
const _265034ca = () => interopDefault(import('../pages/account/user-folders/_contentType/_folderId.vue' /* webpackChunkName: "pages/account/user-folders/_contentType/_folderId" */))
const _0595340c = () => interopDefault(import('../pages/activate/_token.vue' /* webpackChunkName: "pages/activate/_token" */))
const _71458fe0 = () => interopDefault(import('../pages/password-reset/_token.vue' /* webpackChunkName: "pages/password-reset/_token" */))
const _e41ea57a = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _59cb6658,
    name: "account"
  }, {
    path: "/log-out",
    component: _5646c629,
    name: "log-out"
  }, {
    path: "/stripe-callback",
    component: _d53482ae,
    name: "stripe-callback"
  }, {
    path: "/account/details",
    component: _29162878,
    name: "account-details"
  }, {
    path: "/account/user-folders/:contentType",
    component: _1ae15b65,
    name: "account-user-folders-contentType"
  }, {
    path: "/account/user-folders/:contentType?/:folderId?",
    component: _265034ca,
    name: "account-user-folders-contentType-folderId"
  }, {
    path: "/activate/:token?",
    component: _0595340c,
    name: "activate-token"
  }, {
    path: "/password-reset/:token?",
    component: _71458fe0,
    name: "password-reset-token"
  }, {
    path: "/*",
    component: _e41ea57a,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
