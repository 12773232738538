<template>
    <SiteNotice :actions="false">
        <template #message>
            <h5 class="big-body --bold">One last confirmation</h5>
            <p>To make absolutely sure this is you, please enter your password.</p>
            <BaseForm :fields="formFields" :errors="formErrors" @submit="handleSubmit">
                <template #actions>
                    <button class="button --crimson" type="submit">Submit</button>
                </template>
            </BaseForm>
        </template>
    </SiteNotice>
</template>

<script>
import { mapActions } from 'vuex';
import SiteNotice from '@global/SiteNotice';
import BaseForm from '@forms/BaseForm';

export default {
    components: {
        SiteNotice,
        BaseForm,
    },

    data() {
        return {
            formErrors: {},
        };
    },

    computed: {
        formFields: () => [
            {
                name: 'password',
                type: 'password',
            },
        ],
    },

    methods: {
        ...mapActions('user', ['swapEmails']),
        async handleSubmit({ password }) {
            const { success, errors } = await this.swapEmails({ password });
            if (success) {
                this.$notices.show({
                    title: 'All done!',
                    message: 'You have successfully changed your email address',
                    actions: [
                        {
                            link: '/',
                            label: 'Back to Home',
                            style: 'crimson',
                        },
                    ],
                });
            } else {
                this.formErrors = errors;
            }
        },
    },
};
</script>
