<template>
    <span :class="modifierArray" class="link">
        <div v-if="!destination" class="link__anchor body --medium">
            <slot />
        </div>

        <nuxt-link
            v-else-if="isInternalUrl(destination) && !modalForm"
            :to="destination || ''"
            :aria-label="ariaLabel"
            class="link__anchor body --medium"
            :target="target"
        >
            <slot />
        </nuxt-link>

        <span v-else-if="destination && modalForm" @click="launchModal" class="link__anchor body --medium">
            <slot />
            <Icon v-if="renderIcon" :icon="linkIcon" class="link-icon" />
        </span>

        <a
            v-else
            :href="destination"
            :aria-label="ariaLabel"
            :target="isAnchorLink(destination) ? '' : '_blank'"
            class="link__anchor body --medium"
        >
            <slot />

            <Icon v-if="renderIcon" :icon="linkIcon" class="link-icon" />
        </a>
    </span>
</template>

<script>
import links from '@mixin/links';
import modifier from '@mixin/modifier';
import Icon from '@module/Icon';
import CustomForm from '@parse/CustomForm';

export default {
    mixins: [links, modifier],
    props: {
        destination: {
            type: String,
        },
        renderDestinationIcon: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
        },
        ariaLabel: {
            type: String,
        },
        modalForm: {
            type: Boolean,
            default: false,
        },
        target: {
            type: String,
        },
    },
    computed: {
        renderIcon() {
            return !!this.icon || this.renderDestinationIcon;
        },
        linkIcon() {
            return this.icon || 'external';
        },
    },
    methods: {
        launchModal() {
            this.$modals.launch({
                component: CustomForm,
                props: {
                    formUrl: this.destination,
                },
            });
        },
    },
    components: {
        Icon,
    },
};
</script>
